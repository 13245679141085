import { env } from "@/env.mjs";
import mixpanel from "mixpanel-browser";

const EventName = {
    PAGE_VIEW: 'page_viewed',
    SIGN_UP: 'sign_up',
    SIGN_IN: 'sign_in',
    BUTTON_CLICKED: 'button_clicked',
    LINK_CLICKED: 'link_clicked',
    NAV_LINK_CLICKED: 'nav_link_clicked', 
    SEARCHED_INTEREST: 'searched_interest',
    INTEREST_SEARCH_RESULT_CLICK_ACTION: 'interest_search_result_click_action',
    FAQ_CLICKED: 'faq_clicked',
    BLOG_ARTICLE_READ_CLICKED: 'blog_article_read_clicked',
    CHAT_BUTTON_CLICKED: 'chat_button_clicked',
    SHARE_PROFILE_DIALOG_LINK_CLICKED: 'share_profile_dialog_link_clicked',
    SHARED_USER_PROPERTY: 'shared_user_property',
    BUSINESS_WEBSITE_LINK_CLICKED: 'business_website_link_clicked',
    PROFILE_DETAILS_TAB_CLICKED: 'profile_details_tab_clicked',
    LOG_OUT: 'logout',
    REVIEW_USER_DIALOG_LINK_CLICKED: 'review_user_dialog_link_clicked',
    USER_REVIEWED: 'user_reviewed',
    CHAT_INITIATED: 'chat_initiated',
    OTHER_VIDEO_CLICKED: 'other_video_clicked',
    VIDEO_TAGS_TOGGLE_CLICKED: 'video_tags_toggle_clicked',
    VIDEO_TAG_CLICKED: 'video_tag_clicked',
    RATES_DIALOG_BUTTON_CLICKED: 'rates_dialog_button_clicked',
    VIDEO_LIKED: 'video_liked',
    VIDEO_LIKE_REVERSED: 'video_like_reversed',
    VIDEO_DISLIKED: 'video_disliked',
    VIDEO_DISLIKE_REVERSED: 'video_dislike_reversed',
    VIDEO_REPORTED: 'video_reported',
    JOIN_NOW_CLICKED: 'join_now_button_clicked',
    VIDEO_CLICKED: 'video_clicked',
    VIDEO_PLAYED: 'video_played',
    VIDEO_PAUSED: 'video_paused',
    VIDEO_HOVER_PREVIEW_PLAYED: 'video_hover_preview_played',
    START_FREE_TRIAL_BUTTON_CLICKED: 'start_free_trial_button_clicked',
    CLOSED_START_FREE_TRIAL_BANNER: 'closed_start_free_trial_banner',
    GOOGLE_PLAY_BUTTON_CLICKED: 'google_play_button_clicked',
    APP_STORE_BUTTON_CLICKED: 'app_store_button_clicked',
    PAGE_EXIT: 'page_exit',

}as const;

const authProviders = {
    cognito: 'cognito',
    google: 'google'
}as const;

type AuthProvider = (typeof authProviders)[keyof typeof authProviders];

type SignInProps = {
    sign_in_type: 'oauth',
    oauth_provider: any,//'cognito' | 'google'
    //todo: get user [email/id] prop for oauth signin
} | ({
    sign_in_type: 'email',
    user_email: string,
});

type InterestSearchProps = {
    searchQuery: string,
    category: string,
    location: string,
}

type ShareProps = {
    shared_property: 'User Profile' | 'User Video'
    shared_to: 'Facebook' | 'Whatsapp' | 'LinkedIn' | 'Email' | 'Copied Link';
    profile_user_id: string;
    sharer_user_id?: string;
}

export class MixpanelTracking {
    private static _instance: MixpanelTracking;
    public static getInstance(): MixpanelTracking{
        if(MixpanelTracking._instance == null || MixpanelTracking._instance == undefined){
            return (MixpanelTracking._instance = new MixpanelTracking());
        }
        return this._instance;
    }

    public constructor(){
        if(MixpanelTracking._instance){
            throw new Error(
                "Mixpanel Error: An instance of mixpanel already initialized. Use 'MixpanelTracking.getInstance()' instead."
            );
        }
        mixpanel.init(env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV == 'development',
            ignore_dnt: true,
            persistence: 'localStorage'
        });
    }

    protected track(name: string, data: object = {}){
        try {
            mixpanel.track(name, data);
        } catch (error) {
            console.log('Failed to track mixpanel event');
        }
    }

    protected timeEvent(name: string){
        try {
            mixpanel.time_event(name);
        } catch (error) {
            console.log(`Failed to time ${name} mixpanel event`);
        }
    }

    pageViewed(props:{pageUrl: string, page_name: string, viewer_user_id?: string}){
        this.track(EventName.PAGE_VIEW, {
            page_url: props.pageUrl, 
            viewer_user_id: props.viewer_user_id ?? '',
            page_name: props.page_name,
        });
        //*To time and calculate page view duration
        this.timeEvent(EventName.PAGE_EXIT);
    }

    pageExit(props:{pageUrl: string, page_name: string}){
        this.track(EventName.PAGE_EXIT, {
            page_url: props.pageUrl, 
            page_name: props.page_name,
        });
    }


    signUp(timestamp: string){
        this.track(EventName.SIGN_UP, {sign_up_timestamp: timestamp});
    }

    signIn(props: SignInProps){
        this.track(EventName.SIGN_IN, {...props});
    }

    logOut(userid: string){
        this.track(EventName.LOG_OUT, {action:'User logged out', user_id: userid});
    }

    buttonClicked(buttonAction: string){
        this.track(EventName.BUTTON_CLICKED, {button_action: buttonAction});
    }

    linkClicked(linkAction: string){
        this.track(EventName.LINK_CLICKED, {link_action: linkAction});
    }

    navLinkClicked(linkAction: string){
        this.track(EventName.NAV_LINK_CLICKED, {link_action: linkAction});
    }

    searchInterest(params: InterestSearchProps){
        this.track(EventName.SEARCHED_INTEREST,{
            search_query: params.searchQuery,
            search_category: params.category,
            search_country: params.location,
        });
    }

    blogArticleReadClicked(articleTitle: string){
        this.track(EventName.BLOG_ARTICLE_READ_CLICKED,{article_title: articleTitle});
    }

    faqClicked(question: string){
        this.track(EventName.FAQ_CLICKED,{faq_question: question});
    }

    chatButtonClicked({action, userid}:{action: string, userid: string}){
        this.track(EventName.CHAT_BUTTON_CLICKED,{profile_user_id: userid, click_action: action});
    }

    chatInitiated({initiated_from_id, initiated_to_id}:{initiated_from_id: string, initiated_to_id: string}){
        this.track(EventName.CHAT_INITIATED,{initiated_from_id, initiated_to_id});
    }

    shareProfileDialogLinkClicked(userid: string){
        this.track(EventName.SHARE_PROFILE_DIALOG_LINK_CLICKED, {user_id: userid});
    }
    
    sharedUserProperty(props: ShareProps){
        this.track(EventName.SHARED_USER_PROPERTY,{...props});
    }

    businessWebsiteLinkClicked({user_id, business_website}:{user_id: string, business_website: string}){
        this.track(EventName.BUSINESS_WEBSITE_LINK_CLICKED, {
            action: 'User business website link on profile clicked', 
            profile_user_id: user_id,
            business_website_url: business_website,
        });
    }

    profileDetailsTabClicked(props:{tab_title: string, user_id: string, is_profile_owner: boolean}){
        this.track(EventName.PROFILE_DETAILS_TAB_CLICKED,{
            tab_title: props.tab_title, profile_user_id: props.user_id, is_profile_owner: props.is_profile_owner,
        });
    }

    reviewUserDialogLinkClicked({userid, action}:{userid: string, action: string}){
        this.track(EventName.REVIEW_USER_DIALOG_LINK_CLICKED, {
            action: action, profile_user_id: userid
        });
    }

    userReviewCreated(props:{action: string, reviewer_id: string, profile_user_id: string, rating: string}){
        this.track(EventName.USER_REVIEWED,{
            action: props.action,
            profile_user_id: props.profile_user_id,
            reviewer_id: props.reviewer_id,
            user_rating: props.rating,
        });
    }

    otherVideoClicked({video_id, video_title, viewer_user_id, video_author_id}
        :{video_title: string, video_id: string, video_author_id: string,viewer_user_id?: string,}){
        this.track(EventName.OTHER_VIDEO_CLICKED,{
            action: 'Other video of creator clicked on /watch page',
            video_title,
            video_id,
            video_author_id,
            viewer_user_id: viewer_user_id ?? '',
        });
    }

    videoViewTagsToggleClicked(props:{video_id: string, video_author_id: string}){
        this.track(EventName.VIDEO_TAGS_TOGGLE_CLICKED,{
            action: "'Video Tag' toggle in /watch clicked",
            ...props
        });
    }

    videoTagClicked({video_author_id, viewer_user_id, video_id, video_tag, }
        :{video_tag: string, video_id: string, video_author_id: string, viewer_user_id?: string}){
        this.track(EventName.VIDEO_TAG_CLICKED,{
            action: "Video tag in /watch clicked",
            video_tag,
            video_id,
            video_author_id,
            viewer_user_id: viewer_user_id ?? '',
        });
    }

    ratesDialogButtonClicked({video_author_id,}:{video_author_id:string}){
        this.track(EventName.RATES_DIALOG_BUTTON_CLICKED,{
            action: "'Rates' dialog opened on /watch",
            video_author_id,
        });
    }

    interestSearchResultClickAction(props:
        {
            action: string, searcher_user_id: string, result_item_owner_id: string, 
            other_props?: {}
        }, result_category: 'Users' | 'Skills Video' | 'Product & Business Video'){
            this.track(EventName.INTEREST_SEARCH_RESULT_CLICK_ACTION, {
                action: props.action, 
                searcher_user_id: props.searcher_user_id,
                result_category,
                result_item_owner_id: props.result_item_owner_id,
                ...props.other_props
            });
    }

    videoLiked(props:{video_id: string, video_viewer_id: string}){
        this.track(EventName.VIDEO_LIKED, { ...props });
    }

    videoLikeReversed(props:{video_id: string, video_viewer_id: string}){
        this.track(EventName.VIDEO_LIKE_REVERSED, { ...props });
    }

    videoDisliked(props:{video_id: string, video_viewer_id: string}){
        this.track(EventName.VIDEO_DISLIKED, { ...props });
    }

    videoDislikeReversed(props:{video_id: string, video_viewer_id: string}){
        this.track(EventName.VIDEO_DISLIKE_REVERSED, { ...props });
    }

    videoReported(props:{video_id: string, video_reporter_id: string, report_reasons: string}){
        this.track(EventName.VIDEO_REPORTED, {...props});
    }

    joinNowButtonClicked(action: string){
        this.track(EventName.JOIN_NOW_CLICKED, {button_action: action});
    }

    videoClicked(props
        :{action?: string, video_title: string, video_id: string, video_author_id?: string,viewer_user_id?: string, other_props?: {}}){
        this.track(EventName.VIDEO_CLICKED,{
            action: props.action ?? 'Video item clicked',
            video_title: props.video_title,
            video_id: props.video_id,
            video_author_id: props.video_author_id ?? '',
            viewer_user_id: props.viewer_user_id ?? '',
            is_viewer_video_author: props.video_author_id === (props.viewer_user_id ?? ''),
            ...props.other_props
        });
    }

    startFreeTrialClicked(){
        this.track(EventName.START_FREE_TRIAL_BUTTON_CLICKED, {
            action: "'Start Free Trial' button on free trial banner clicked"
        });
    }

    closedStartFreeTrialBanner(){
        this.track(EventName.CLOSED_START_FREE_TRIAL_BANNER);
    }

    googlePlayButtonClicked(){
        this.track(EventName.GOOGLE_PLAY_BUTTON_CLICKED,{
            action: "'Google Play' button clicked",
        })
    }

    appStoreButtonClicked(){
        this.track(EventName.APP_STORE_BUTTON_CLICKED,{
            action: "'App Store' button clicked",
        });
    }

    //*duty ceded to videoHoverPreviewPlayed method for now
    videoPlayed(props: {video_id: string, vidoe_title: string}){
        this.track(EventName.VIDEO_PLAYED,{
            ...props
        });
    }

    videoPaused(props: {video_id: string, vidoe_title: string}){
        this.track(EventName.VIDEO_PAUSED,{
            ...props
        });
    }

    videoHoverPreviewPlayed(props: {video_id: string, vidoe_title: string}){
        this.track(EventName.VIDEO_HOVER_PREVIEW_PLAYED,{
            ...props
        });
    }

    

}