"use client";

import Link from "next/link";

import { useState, useEffect } from "react";

import { usePathname } from "next/navigation";

import { Button } from "@/components/ui/button";

import { Cookie } from "lucide-react";
import path from "path";

export default function CookieBanner() {
	const pathname = usePathname();
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(localStorage.getItem("first-visit") === "false" ? false : true);
	}, []);

	useEffect(() => {
		if (
			localStorage.getItem("first-visit") !== "false" &&
			pathname === "/policy"
		) {
			setShow(false);
		} else {
			setShow(
				localStorage.getItem("first-visit") === "false" ? false : true
			);
		}
	}, [pathname]);

	return (
		<>
			{show && (
				<>
					<div className="fixed top-0 left-0 m-0 p-0 w-[100%] h-[100%] bg-slate-200/80 backdrop-blur-[3px] z-[8]"></div>

					<div className="z-10 relative flex items-center justify-center">
						<div className="flex justify-center items-center p-9 bg-white rounded-xl fixed bottom-0 sm:bottom-[30%]">
							<div className="flex flex-wrap flex-col items-center justify-center max-w-lg">
								<Cookie fill="#f59e0b" stroke="#b45309" size={50} />
								<h2 className="font-bold text-blue-500 text-3xl mb-3">
									Bazaar Room uses cookies.
								</h2>
								<p>
									We use cookies to improve our services, enhance your
									experience, and to see which areas and features of
									our services are popular. By clicking &lsquo;Accept
									All&rsquo; you agree to our cookie policy.
								</p>

								<Link
									href="/policy"
									className="text-blue-500 mt-2 font-medium transition-all duration-400 origin-left border-b-2 border-transparent hover:border-blue-500">
									Find out more
								</Link>

								<Button
									className="mt-7 w-full hover:bg-blue-500"
									onClick={() => {
										localStorage.setItem("first-visit", "false");
										setShow(false);
									}}>
									Accept All
								</Button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
