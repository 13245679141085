"use client"

import { useMixpanelPageRouteChangeEvent } from "@/hooks/useMixpanelPageRouteChangeEvent"
import { MixpanelTracking } from "./mixpanel-browser-analytics";

export default function TrackPageExit() {
    useMixpanelPageRouteChangeEvent(({pageName, pageUrl})=>{
        MixpanelTracking.getInstance().pageExit({
            pageUrl: pageUrl,
            page_name: pageName,
        });
    });

    return(
        <></>
    );
}