"use client";

import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { User, LogOut, UserCog } from "lucide-react";
import { Session } from "next-auth";
import Image from "next/image";
import Link from "next/link";
import { signOut, signIn } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";

import { useState } from "react";
import { useNotificationState } from "@/providers/notification-provider";
import { MixpanelTracking } from "@/lib/mixpanel-browser-analytics";
import Script from "next/script";

export const NavBar: React.FC<{ session: Session | null; isAdmin: boolean }> = (
	props
) => {
	const hiddenHeader = ["/meeting"];
	const session = props.session;
	const pathname = usePathname();
	const hideHeader = hiddenHeader.includes(pathname);
	let firstname = "U";
	let lastname = "U";

	let [open, setOpen] = useState<boolean>(false);
	const { newMessage } = useNotificationState();

	const navTransition = ``;
	if (session) {
		const nameSplit = session?.user.name?.split(" ");
		if (nameSplit) {
			firstname = nameSplit![0];
			lastname = nameSplit![nameSplit?.length! - 1];
		}
	}

	
	const logOutUser = () => {
		MixpanelTracking.getInstance().logOut(session?.user.id ?? "");
		signOut();
	};

	
	return (
		<>	
			<Script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" onLoad={() => {
				const googleTranslateElementInit = () => {
					new (window as any).google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element1')
				}
				(window as any).googleTranslateElementInit = googleTranslateElementInit;
			}} />

			<span id="google_translate_element1"></span>

			{!hideHeader && (
				<>
					{/*white background behind scrollbar*/}
					<div className="z-[-1] fixed top-0 right-0 m-0 p-0 w-[50px] h-[100%] bg-white"></div>

					<header className="bg-gray-100">
						<nav className="flex flex-col lg:flex-row lg:items-center  lg:pt-4 lg:pb-2 lg:mx-5 ">
							<div className="flex flex-col pb-2 lg:flex-row lg:items-center lg:flex-grow ">
								<div className=" flex justify-between items-start mt-4 ">
									<Link href="/" rel="canonical">
										<Image
											src="/images/bazaarroom-logo.png"
											alt="Logo"
											height={45}
											width={205}
											className="mr-1 ml-8 mb-6 "
										/>
									</Link>

									<div className="lg:hidden border border-gray-500 rounded-md px-2 py-0.5 mr-8 transition-all duration-150 ease-in hover:border-4 active:border-4">
										<HamburgerMenuIcon
											onClick={() => setOpen(!open)}
											className="lg:hidden  min-h-8 w-auto cursor-pointer  "
										/>
									</div>
								</div>

								<div className="flex-grow"></div>
								<ul
									className={`flex flex-col items-center space-y-3 ml-3  lg:flex-row lg:items-center lg:space-x-4 
lg:mr-2  overflow-hidden lg:transition-none lg:space-y-0 transition-all duration-500 ease-in ${
										open
											? "max-h-80 lg:max-h-none"
											: "max-h-0 lg:max-h-none"
									}`}>
									<li>
										<Link
											href="/"
											rel="canonical"
											className="text-gray-500 hover:text-blue-600"
											onClick={() =>
												MixpanelTracking.getInstance().navLinkClicked(
													"'Home' link on nav bar clicked"
												)
											}>
											Home
										</Link>
									</li>
									<li>
										<Link
											href="/howto"
											className="text-gray-500 hover:text-blue-600"
											onClick={() =>
												MixpanelTracking.getInstance().navLinkClicked(
													"'How It Works' link on nav bar clicked"
												)
											}>
											How It Works
										</Link>
									</li>
									<li>
										<Link
											href="/skills"
											className="text-gray-500 hover:text-blue-600"
											onClick={() =>
												MixpanelTracking.getInstance().navLinkClicked(
													"'Learn Skills' link of nav bar clicked"
												)
											}>
											Learn Skills
										</Link>
									</li>
									<li>
										<Link
											href="/products-and-business"
											className="text-gray-500 hover:text-blue-600"
											onClick={() =>
												MixpanelTracking.getInstance().navLinkClicked(
													"'Product & Businesses' link on nav bar clicked"
												)
											}>
											Products & Businesses
										</Link>
									</li>
									<li>
										<Link
											href="/about-us"
											className="text-gray-500 hover:text-blue-600"
											onClick={() =>
												MixpanelTracking.getInstance().navLinkClicked(
													"'About Us' link on nav bar clicked"
												)
											}>
											About Us
										</Link>
									</li>
									<li>
										<Link
											href="/resources"
											className="text-gray-500 hover:text-blue-600"
											onClick={() =>
												MixpanelTracking.getInstance().navLinkClicked(
													"'Resources' link on nav bar clicked"
												)
											}>
											Resources
										</Link>
									</li>

									{!session?.user && (
										<>
											<li>
												<button
													onClick={() => {
														signIn();
														MixpanelTracking.getInstance().navLinkClicked(
															"'Login' link on nav bar clicked"
														);
													}}
													className={`text-gray-500 hover:text-blue-600 `}
													aria-label="Login">
													Login
												</button>
											</li>
										</>
									)}

									{session?.user && (
										<>
											<li className="indicator">
												<Link
													href="/chat"
													className="text-gray-500 active:text-blue-600 hover:text-blue-600"
													onClick={() =>
														MixpanelTracking.getInstance().navLinkClicked(
															"'Chat' link on nav bar clicked"
														)
													}>
													Chat{" "}
													{newMessage && (
														<span className="indicator-item badge badge-secondary badge-xs"></span>
													)}
												</Link>
											</li>
											{props.isAdmin && (
												<li>
													<Link
														href="/admin"
														className="text-gray-500 active:text-blue-600 hover:text-blue-600"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Admin' link on nav bar clicked"
															)
														}>
														Admin
													</Link>
												</li>
											)}
											<li>
												<DropdownMenu>
													<DropdownMenuTrigger asChild>
														<Avatar>
															<AvatarImage
																src={session.user.image!}
																alt="Avatar"
															/>
															<AvatarFallback className="cursor-pointer hover:text-blue-600 justify-start ">
																{firstname
																	.charAt(0)
																	.toUpperCase() +
																	"" +
																	lastname
																		.charAt(0)
																		.toUpperCase()}
															</AvatarFallback>
														</Avatar>
													</DropdownMenuTrigger>
													<DropdownMenuContent className="w-56">
														<DropdownMenuLabel>
															{session.user.name}
														</DropdownMenuLabel>
														{/* BUG should be session.user.role === "admin" */}
														{session.user.role === "admin" && (
															<>
																<DropdownMenuSeparator />
																<DropdownMenuItem>
																	<UserCog className="mr-2 h-4 w-4" />
																	<Link
																		href={`/admin`}
																		onClick={() =>
																			MixpanelTracking.getInstance().navLinkClicked(
																				"'Admin' link in nav bar dropdown clicked"
																			)
																		}>
																		{" "}
																		<span>Admin</span>
																	</Link>
																</DropdownMenuItem>
															</>
														)}
														<DropdownMenuSeparator />
														<DropdownMenuItem>
															<User className="mr-2 h-4 w-4" />
															<Link
																href={`/profile/${session.user.id}`}
																onClick={() =>
																	MixpanelTracking.getInstance().linkClicked(
																		"'Profile' link in nav bar dropdown clicked"
																	)
																}>
																{" "}
																<span>Profile</span>
															</Link>
														</DropdownMenuItem>
														<DropdownMenuSeparator />
														<DropdownMenuItem
															onClick={() => {
																logOutUser();
																MixpanelTracking.getInstance().linkClicked(
																	"'Log out' link in dropdown clicked"
																);
															}}>
															<LogOut className="mr-2 h-4 w-4" />
															<span>Log out</span>
														</DropdownMenuItem>
													</DropdownMenuContent>
												</DropdownMenu>
											</li>
										</>
									)}
								</ul>
							</div>
						</nav>
					</header>
				</>
			)}
		</>
	);
};
