"use client";

import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";

export const useMixpanelPageRouteChangeEvent = (onPathnameChange: (props: {
    pageName: string, 
    pageUrl: string
})=>void)=>{
    ///*Get current route
    const pathname = usePathname(); 

    ///*Save pathname on component mount to a Ref
    const savedPathnameRef = useRef(pathname);

    ///*Save url on page mount to a Ref
    const getCurrentPageUrl = () =>{
        if(typeof window === "undefined"){
            return '';
        }
        return window.location.href;
    }
    const pageurl = getCurrentPageUrl();
    const savedPageUrl = useRef(pageurl);

    useEffect(()=>{
        ///*If Ref changes, call callback function
        if(savedPathnameRef.current  !== pathname){
            
            onPathnameChange.call(null, {
                pageName: savedPathnameRef.current, 
                pageUrl: savedPageUrl.current,
            });
            
            ///*Update pathname Ref
            savedPathnameRef.current = pathname;

            ///*Update url Ref
            savedPageUrl.current = window.location.href;
        }
    },[pathname, onPathnameChange]);
}