"use client";
import { useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { isMobile } from "react-device-detect";
import { usePathname } from "next/navigation";
import { MixpanelTracking } from "@/lib/mixpanel-browser-analytics";

import CookieBanner from "@/components/cookie-banner";
// import UserSurvey from "./user-survey";

import { X } from "lucide-react";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";

export default function Footer() {
	const hiddenFooter = ["/meeting"];
	const currentYear = new Date().getFullYear();
	const pathname = usePathname();
	const hideFooter = hiddenFooter.includes(pathname);

	const getMobileOS = () => {
		const ua = navigator.userAgent;
		if (/android/i.test(ua)) {
			return "Android";
		} else if (
			/iPad|iPhone|iPod/.test(ua) ||
			(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
		) {
			return "iOS";
		}
		return "Other";
	};

	const icons: { platform: string; link: string }[] = [
		{
			platform: "Twitter",
			link: "https://twitter.com/BazaarRoom?t=XFj39Ioq4Rc46-yVNCHO9w&s=03",
		},

		{
			platform: "Facebook",
			link: "https://www.facebook.com/Bazaarroom?mibextid=7cd5pb",
		},

		{
			platform: "TikTok",
			link: "https://www.tiktok.com/@bazaarroom",
		},

		{
			platform: "Instagram",
			link: "https://www.instagram.com/bazaarroom.com2022/",
		},

		{
			platform: "Pinterest",
			link: "https://pin.it/334cUS5",
		},

		{
			platform: "Snapchat",
			link: "https://www.snapchat.com/add/bazaarroom.com?share_id=iM4T04TyjPg&locale=en-US",
		},

		{
			platform: "Hipi",
			link: "http://hipi.co.in/@bazaarroom.com",
		},

		{
			platform: "YouTube",
			link: "https://www.youtube.com/@bazaarroomdotcom749",
		},

		{
			platform: "MeWe",
			link: "https://mewe.com/bazaarroom",
		},

		

		{
			platform: "Medium",
			link: "https://medium.com/@bazaarroom.com",
		},

		{
			platform: "Quora",
			link: "https://www.quora.com/profile/Bazaar-Room",
		},

		{
			platform: "Flipboard",
			link: "https://flipboard.com/@flbd1iu2juda1se?from=share&utm_source=flipboard&utm_medium=curator_share",
		},

		{
			platform: "LinkedIn",
			link: "http://www.linkedin.com/in/bazaar-room-819a57172",
		},
	];

	return (
		<>
			{!hideFooter && (
				<footer className="bg-gray-100">
					<div className="py-28 flex flex-col">
						<div className="grid grid-cols-1 gap-5">
							<div className="px-10 py-10 bg-blue-100">
								<span className="text-center font-bold flex flex-col space-y-10 items-center justify-center sm:flex-row">
									<picture>
										<source srcSet="/images/icon.webp" width={50} />
										<Image
											src="/images/icon.png"
											alt="Logo"
											width={50}
											height={50}
										/>
									</picture>
									&nbsp; Streamline Your Experience With BazaarRoom
									Messenger&nbsp; &nbsp;
									{isMobile ? (
										<a
											href={
												getMobileOS() === "iOS"
													? "https://apps.apple.com/us/app/bazaar-room-messenger/id6446254595"
													: "https://play.google.com/store/apps/details?id=com.bazaarroom.messenger"
											}
											className="border-none bg-blue-500 text-white px-8 py-2 text-center no-underline text-base rounded-md flex items-center sm:!mt-0"
											onClick={() =>
												MixpanelTracking.getInstance().linkClicked(
													`'Download' link on footer clicked. Device Platform: [${getMobileOS()}]`
												)
											}>
											Download
										</a>
									) : (
										<>
											<a
												href="https://apps.apple.com/us/app/bazaar-room-messenger/id6446254595"
												className="border-none bg-blue-500 text-white px-8 py-2 text-center no-underline text-base rounded-md flex items-center sm:!mt-0"
												onClick={() =>
													MixpanelTracking.getInstance().appStoreButtonClicked()
												}
												rel="noopener noreferrer"
												target="_blank">
												<Image
													src="/images/app-store.svg"
													alt="App Store Icon"
													height={20}
													width={20}
													className="mr-1"
												/>
												App Store
											</a>
											&nbsp;&nbsp;
											<a
												href="https://play.google.com/store/apps/details?id=com.bazaarroom.messenger"
												className="border-none bg-blue-500 text-white px-8 py-2 text-center no-underline text-base rounded-md flex items-center sm:!mt-0"
												onClick={() =>
													MixpanelTracking.getInstance().googlePlayButtonClicked()
												}
												rel="noopener noreferrer"
												target="_blank">
												<Image
													src="/images/google-play.svg"
													alt="Google Play Icon"
													height={20}
													width={20}
													className="mr-1"
												/>
												Google Play
											</a>
										</>
									)}
								</span>
							</div>

							<div className="pt-10 px-0 mt-4">
								<div className="container">{/* <UserSurvey /> */}</div>

								<div className="px-5 flex py-10 mt-4 flex-col">
									<div className="pt-4">
										<div className="flex w-full justify-around lg:justify-center">
											<ul className="text-left m-0 p-0 flex flex-col justify-between lg:flex-row lg:space-x-4 lg:text-center">
												<li className="hover:font-bold">
													<Link
														href="/"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Home' link on footer clicked"
															)
														}>
														Home
													</Link>
												</li>
												<li className="hover:font-bold">
													<Link
														href="/legal"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Legal' link on footer clicked"
															)
														}>
														Legal
													</Link>
												</li>
												<li className="hover:font-bold">
													<Link
														href="/policy"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Our Policy' link on footer clicked"
															)
														}>
														Our Policy
													</Link>
												</li>
												<li className="hover:font-bold">
													<Link
														href="/howto"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'How It Works' link on footer clicked"
															)
														}>
														How It Works
													</Link>
												</li>
											</ul>
											<ul className="text-right ml-4 p-0 flex flex-col justify-between lg:flex-row lg:space-x-4 lg:text-center">
												<li className="hover:font-bold">
													<Link
														href="/skills"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Learn Skills' link on footer clicked"
															)
														}>
														Learn Skills
													</Link>
												</li>
												<li className="hover:font-bold">
													<Link
														href="/products-and-business"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Products & Business' link on footer clicked"
															)
														}>
														Products & Businesses
													</Link>
												</li>
												<li className="hover:font-bold">
													<Link
														href="/about-us"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'About Us' link on footer clicked"
															)
														}>
														About Us
													</Link>
												</li>
												<li className="hover:font-bold">
													<Link
														href="/resources"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Resources' link on footer clicked"
															)
														}>
														Resources
													</Link>
												</li>
												<li className="hover:font-bold">
													<a
														href="mailto:try@bazaarroom.com"
														onClick={() =>
															MixpanelTracking.getInstance().navLinkClicked(
																"'Contact Us' link on footer clicked"
															)
														}>
														Contact Us
													</a>
												</li>
											</ul>
										</div>
										<div className="my-6 border-t border-gray-200"></div>
									</div>
									<div className="">
										<div className="text-center">
											<ul className="m-0 p-0">
												<li>
													&copy; Copyright {currentYear} BazaarRoom
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/*Icons*/}
						<div className="self-center max-w-xl">
							<div className="px-15 flex flex-wrap gap-7 justify-center items-center flex-row">
								{icons.map((icon) => {
									return (
										<TooltipProvider key={icon.platform}>
											<Tooltip>
												<TooltipTrigger>
													<Link href={icon.link}>
														<Image
															src={`/images/icon-${icon.platform}.svg`}
															alt={icon.platform}
															width={32}
															height={32}
														/>
													</Link>
												</TooltipTrigger>

												<TooltipContent>
													{icon.platform}
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									);
								})}
							</div>
						</div>
					</div>

					<CookieBanner />
				</footer>
			)}
		</>
	);
}
